<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="员工昵称：">
        <el-input v-model="searchForm.nickname" size="small"></el-input>
      </el-form-item>
      <el-form-item label="员工手机号：">
        <el-input v-model="searchForm.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="注册时间：">
        <el-date-picker
          v-model="searchForm.time"
          value-format="timestamp"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="transactionTime" label="员工信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :name="row.nickname" :avatar="row.avatar"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
      <el-table-column label="累计积分" align="center">
        <template v-slot="{ row }">{{
          Number(row.commission_amount) + Number(row.goods_commission_amount)
        }}</template>
      </el-table-column>
      <el-table-column
        prop="can_withdraw_amount"
        label="现有积分数"
        align="center"
      ></el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="transactionTime" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button v-if="Number(row.can_withdraw_amount)" type="text" @click="settlementFn(row)"
            >结 算</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <!-- 修改身份弹框 -->
    <el-dialog title="结算" :visible.sync="settlementFlag" width="600px">
      <el-form label-width="100px" class="dialogForm">
        <el-form-item
          v-for="(item, index) in settlementItem.content"
          :key="index"
          :label="item.name + '：'"
          >{{ item.value }}</el-form-item
        >
        <el-form-item label="打款金额：">
          <el-input v-model="settlementItem.realMoney"></el-input>
        </el-form-item>
        <el-form-item label="*打款凭证：">
          <ReadyUploadSource
            :path="settlementItem.paymentVoucher"
            @getSource="(val) => (settlementItem.paymentVoucher = val.path)"
            @removeThis="() => (settlementItem.paymentVoucher = '')"
            :showStyle="{
              width: '200px',
              height: '100px'
            }"
          ></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入备注"
            v-model="settlementItem.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settlementFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureUpdate()">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import UserInfo from '@/components/userInfo'
import { getDateformat } from '@/util/getDate.js'
import Paging from '@/components/paging'
import ReadyUploadSource from '@/components/readyUploadSource'
import _ from 'lodash'
export default {
  components: {
    UserInfo,
    Paging,
    ReadyUploadSource
  },
  data() {
    return {
      getDateformat,
      searchForm: {
        mobile: '',
        nickname: '',
        time: []
      },
      page: 1,
      rows: 10,
      total: 0,
      dataList: [],
      settlementFlag: false,
      settlementItem: {}
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
      } else {
        this.page = val
      }
      this.getDataList()
    },
    cancelSearch() {
      this.page = 1
      this.searchForm = {
        mobile: '',
        nickname: '',
        time: []
      }
      this.getDataList()
    },
    // 点击搜索进行查询
    search() {
      this.page = 1
      this.getDataList()
    },
    // 获取页面列表数据
    getDataList() {
      let obj = {
        page: this.page,
        rows: this.rows
      }
      if (this.searchForm.mobile) {
        obj.mobile = this.searchForm.mobile
      }
      if (this.searchForm.nickname) {
        obj.nickname = this.searchForm.nickname
      }
      let time = this.searchForm.time
      if (time.length) {
        // 如果需要按时间查询
        obj.start_time = Math.ceil(time[0].getTime() / 1000)
        obj.end_time = Math.ceil(time[1].getTime() / 1000)
        if (obj.start_time == obj.end_time) {
          obj.end_time += 60 * 60 * 24 - 1
        }
      }
      this.$axios.post(this.$api.samecity.distributionList, obj).then((res) => {
        if (res.code == 0) {
          this.dataList = res.result.list
          this.total = res.result.total_number
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 开启结算弹框
    settlementFn(row) {
      this.settlementItem = _.cloneDeep(row)
      this.settlementItem.content = JSON.parse(this.settlementItem.content)
      let len = this.settlementItem.content.length
      for (let i = len - 1; i >= 0; i--) {
        let name = this.settlementItem.content[i].name
        if (
          name == '身份证号' ||
          name == '身份证正面' ||
          name == '身份证反面' ||
          name == '学历' ||
          name == '详细地址' ||
          name == '工作地址'
        ) {
          this.settlementItem.content.splice(i, 1)
        }
      }
      this.$set(this.settlementItem, 'realMoney', this.settlementItem.can_withdraw_amount)
      this.$set(this.settlementItem, 'paymentVoucher', '')
      this.$set(this.settlementItem, 'remark', '')
      this.settlementFlag = true
    },
    // 确认结算按钮
    sureUpdate() {
      if (!this.settlementItem.paymentVoucher) {
        this.$message.warning('请上传打款凭证')
        return
      }
      if (!this.settlementItem.realMoney) {
        this.$message.warning('请输入正确的打款金额')
        return
      }
      let obj = {
        id: this.settlementItem.id,
        money: this.settlementItem.realMoney,
        proof: this.settlementItem.paymentVoucher
      }
      if (this.settlementItem.remark) {
        obj.remark = this.settlementItem.remark
      }
      this.$axios.post(this.$api.samecity.settlement, obj).then((res) => {
        if (res.code == 0) {
          this.$message.success('结算成功')
          this.getDataList()
          this.settlementFlag = false
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .dialogForm {
    .el-form-item {
      margin-bottom: 10px;
      .el-input {
        width: 200px;
      }
    }
  }
}
</style>
